const serviceUrlPro = 'https://gateway-admin.xiaowuxiongdi.com/api'

// const serviceUrl = 'http://172.16.26.76:8051' // wy
const serviceUrlDev = 'http://172.16.24.200:8011' // zwx
// const serviceUrlDev = 'http://172.16.26.44:8041' // dy
const serviceUrlUat = 'http://gateway.xiaowukeji.cn/api' // uat
const serviceUrlTest = 'http://172.16.24.200:8011' // 测试环境

const envMap = {
  development: serviceUrlDev,
  production: serviceUrlPro,
  buildTest: serviceUrlTest,
  buildUat: serviceUrlUat,
}
const serviceUrl = envMap[process.env.NODE_ENV]
export default {
  serviceUrl,
  loginEndpoint: `${serviceUrl}/sso/doLoginByTicket`,
  ssoLoginEndpoint: `${serviceUrl}/sso/doLogin`,
  ssoRedirectUrlEndpoint: `${serviceUrl}/sso/redirectUrl`,
  userInfoEndpoint: `${serviceUrl}/sso/userInfo`,
  isLoginEndpoint: `${serviceUrl}/sso/isLogin`,
  ssoAuthUrlEndpoint: `${serviceUrl}/sso/getSsoAuthUrl`,
  logoutEndpoint: `${serviceUrl}/auth/logout`,
  routeEndpoint: `${serviceUrl}/base/route`,
  menuEndpoint: `${serviceUrl}/base/menu`,

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageSSOTokenKeyName: 'ssoToken',
  storageRefreshTokenKeyName: 'refreshToken',

}
